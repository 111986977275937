import {useState, useEffect} from "react";
import {fetchInventory} from "../helpers/fetchInventory";

export const useInventory = () => {

  const [isLoading, setIsLoading] = useState(true);
  const [inventory, setInventory] = useState([]);

  useEffect(() => {
    fetchInventory().then(vehicles => {
      setIsLoading(false);
      setInventory(vehicles);
    });
  } , []);

  return {
    isLoading,
    inventory
  };
}