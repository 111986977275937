import './Header.scss';
import TopBar from "../TopBar/TopBar";
import MainMenu from "../MainMenu/MainMenu";

function Header() {
    return (
      <header>
        <TopBar/>

        <MainMenu/>
      </header>
    );
}

export default Header;
