import './TopBar.scss';

function TopBar() {
    return (
        <nav className="top navbar navbar-expand-lg navbar-dark bg-primary py-1 d-none d-lg-block">
            <div className="container">
                <div className="w-100 d-flex justify-content-end">
                    <ul className="navbar-nav d-flex flex-row me-5">
                        <li className="nav-item">
                            <a href="/about" className="text-small nav-link px-2">About</a>
                        </li>

                        <li className="nav-item">
                            <a href="/contact" className="text-small nav-link px-2">Contact Us</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default TopBar;
