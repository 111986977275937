import './SearchResult.scss';
import Vehicle from "../Vehicle/Vehicle";
import {useInventory} from "../../hooks/useInventory";
import {useState} from "react";

function SearchResult({
    results, currentPage, total, currentSort, setCurrentSort, firstPage, previousPage, nextPage, LastPage
}) {
    const sortOptions = {
      price_asc: "Price: Low - High",
      price_desc: "Price: High - Low",
      miles_asc: "Miles: Low - High",
      miles_desc: "Miles: High - Low",
      year_asc: "Year: Old - New",
      year_desc: "Year: New - Old",
      make_asc: "Make: A-Z",
      make_desc: "Make: Z-A",
    };

  return (
    <>
      <div className="section-2 container py-3">
        <div className="row justify-content-between">

          <div className="col-6 align-items-center">
            Results: { total }
          </div>

          <div className="col-6 text-end">
            <div className="dropdown">
              <a className="btn btn-light btn-sm dropdown-toggle" href="#" role="button" id="sortBy"
                 data-bs-toggle="dropdown" aria-expanded="false">
                <span>Sort by</span> { sortOptions[currentSort] === undefined ? 'Default' : sortOptions[currentSort] }
              </a>

              <ul className="dropdown-menu" aria-labelledby="sortBy">
                { sortOptions[currentSort] !== undefined && <li className="dropdown-item"><a href="#" onClick={() => setCurrentSort('') }>Default</a></li> }
                { sortOptions[currentSort] !== 'price_asc' && <li className="dropdown-item"><a href="#" onClick={() => setCurrentSort('price_asc') }>Price: Low - High</a></li> }
                { sortOptions[currentSort] !== 'price_desc' && <li className="dropdown-item"><a href="#" onClick={() => setCurrentSort('price_desc') }>Price: High - Low</a></li> }
                { sortOptions[currentSort] !== 'miles_asc' && <li className="dropdown-item"><a href="#" onClick={() =>  setCurrentSort('miles_asc') }>Miles: Low - High</a></li> }
                { sortOptions[currentSort] !== 'miles_desc' && <li className="dropdown-item"><a href="#" onClick={() =>  setCurrentSort('miles_desc') }>Miles: High - Low</a></li> }
                { sortOptions[currentSort] !== 'year_asc' && <li className="dropdown-item"><a href="#" onClick={() => setCurrentSort('year_asc') }>Year: Old - New</a></li> }
                { sortOptions[currentSort] !== 'year_desc' && <li className="dropdown-item"><a href="#" onClick={() =>  setCurrentSort('year_desc') }>Year: New - Old</a></li> }
                { sortOptions[currentSort] !== 'make_asc' && <li className="dropdown-item"><a href="#" onClick={() =>  setCurrentSort('make_asc') }>Make: A-Z</a></li> }
                { sortOptions[currentSort] !== 'make_desc' && <li className="dropdown-item"><a href="#" onClick={() =>  setCurrentSort('make_desc') }>Make: Z-A</a></li> }
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="section-3 container">

        <div className="row">
          { results.map((vehicle) => {
            return <Vehicle key={ vehicle.id } data={ vehicle }/>;
          }) }
        </div>

        <div className="row pt-3 pb-5">
          <div className="col-6 text-start">

            <button
              type="button"
              className="btn btn-info btn-lg me-2 d-none"
              onClick={firstPage}
              disabled={currentPage === 0}
            >
              <i className="fa-solid fa-angles-left"/>
            </button>

            <button
              type="button"
              className="btn btn-info btn-lg"
              onClick={previousPage}
              disabled={currentPage === 0}
            >
              <i className="fa-solid fa-angle-left"/> Back
            </button>

          </div>

          <div className="col-6 d-flex align-items-center justify-content-center d-none">
            Page {(currentPage / 12) + 1} of {Math.ceil(total)}
          </div>

          <div className="col-6 text-end">
            <button
              type="button"
              className="btn btn-info btn-lg me-2"
              onClick={nextPage}
              disabled={currentPage + 12 >= total}
            >
              Next <i className="fa-solid fa-angle-right"/>
            </button>

            <button
              type="button"
              className="btn btn-info btn-lg d-none"
              onClick={LastPage}
              disabled={currentPage + 12 >= total}
            >
              <i className="fa-solid fa-angles-right"/>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default SearchResult;
