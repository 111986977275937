import Slider from "react-slick";
import carImage from '../../assets/images/car.jpg';
import './Car.scss';
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Similar from "../../components/Similar/Similar";
import {useParams} from "react-router-dom";
import {useInventory} from "../../hooks/useInventory";
import {useEffect, useState} from "react";
function Car() {
  const {isLoading, inventory} = useInventory();
  const [item, setItem] = useState({
    body_type: "",
    city_mpg: "",
    created_at: "2022-10-18T04:35:47.000000Z",
    cylinder: 0,
    description: "",
    doors: "",
    drivetrain: "",
    engine: "",
    equipments: [],
    exterior_color: null,
    fuel_type: "",
    highway_mpg: "",
    id: null,
    interior_Color: null,
    make: "",
    model: "",
    model_number: "",
    odometer: "",
    photos: [
      carImage
    ],
    status: 1,
    stock_number: "",
    transmission: "",
    transmission_detail: "",
    trim: "",
    updated_at: "",
    vehicle_class: 2,
    vehicle_price: "0.00",
    vin: "",
    year: ""
  });
  const [similars, setSimilars] = useState([]);
  const params = useParams();
  let Number = Intl.NumberFormat('en-US');

  useEffect(() => {
    let cars = inventory;
    let _similar = inventory;
    let _car = {
      body_type: "",
      city_mpg: "",
      created_at: "2022-10-18T04:35:47.000000Z",
      cylinder: 0,
      description: "",
      doors: "",
      drivetrain: "",
      engine: "",
      equipments: [],
      exterior_color: null,
      fuel_type: "",
      highway_mpg: "",
      id: null,
      interior_Color: null,
      make: "",
      model: "",
      model_number: "",
      odometer: "",
      photos: [
        carImage
      ],
      status: 1,
      stock_number: "",
      transmission: "",
      transmission_detail: "",
      trim: "",
      updated_at: "",
      vehicle_class: 2,
      vehicle_price: "0.00",
      vin: "",
      year: ""
    };

    if (params.id) {
      cars = cars.filter(car => parseInt(car.id) === parseInt(params.id));
      _car = cars[0];
    }

    _similar = _similar.filter(car => {
      return parseInt(car.id) !== parseInt(params.id) && ( car.make === _car.make || car.model === _car.model || car.drivetrain === _car.drivetrain);
    })

    if (typeof cars[0] !== 'undefined')
      setItem(_car);

    if ( _similar.length > 0 ) {
      setSimilars(_similar.slice(0, 3));
    }

  }, [params, inventory, item]);

  const sliderXsSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1 ,
    slidesToScroll: 1,
    arrows: true,
  };

  const sliderMdSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3 ,
    slidesToScroll: 1,
    arrows: true,
  };

  const sliderLgSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5 ,
    slidesToScroll: 1,
    arrows: true,
  };

  if ( !params.id ) {
    return (<h1 style={{textAlign: "center"}}>Not found</h1>);;
  }

  if (isLoading) {
    return (<h1 style={{textAlign: "center"}}>Loading</h1>);
  }

  return (
    <>
      <Header/>

      <div className="page-car content-wrapper">

        <div className="main-banner d-flex align-items-end"
          style={{backgroundImage: `url(${item.photos[0]})`}}
        >
          <div className="label">
            <div className="container">
              <div className="row">
                <div className="col-12 col-xl-9">
                  <div className="car-id">{item.stock_number}</div>

                  <h1>{item.year} {item.make} {item.model}</h1>

                  <div className="cart-summay"> {item.body_type} · { Number.format(parseInt(item.odometer)) } milles</div>
                </div>

                <div className="col-12 col-xl-3 d-flex flex-column flex-md-flex align-items-center justify-content-end">
                  <span className="car-price">${ Number.format(parseInt(item.vehicle_price)) }</span>
                  <a href="/apply" className="btn btn-success">Apply now</a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section-gallery gallery-sm d-md-none">
          <Slider {...sliderXsSettings}>

            { item.photos.map((photo, index) => {
              return <div  key={ index } >
                <img src={ photo } className="img-fluid" alt=""/>
              </div>;
            }) }

          </Slider>
        </div>

        <div className="section-gallery gallery-md d-none d-md-block d-lg-none">
          <Slider {...sliderMdSettings}>

            { item.photos.map((photo, index) => {
              return <div className="p-1" key={ index } >
                <img src={ photo } className="img-fluid" alt=""/>
              </div>;
            }) }

          </Slider>
        </div>

        <div className="section-gallery gallery-lg d-none d-lg-block ">
          <Slider {...sliderLgSettings}>

            { item.photos.map((photo, index) => {
              return <div  className="p-1" key={ index } >
                <img src={ photo } className="img-fluid" alt="photo"/>
              </div>;
            }) }

          </Slider>
        </div>

        <div className="section-detail py-5 px-3">
          <div className="content container">

            <h2 className="text-center mb-5">VEHICLE DETAILS</h2>

            <p>
              { item.description }
            </p>

            <h3 className="mb-2 mb-3">Vehicle Equipment List</h3>

            <ul>
              { item.equipments.map((equipment, index) => {
                return <li key={index}>{ equipment }</li>;
              }) }
            </ul>
          </div>
        </div>

        <div className="section-price pt-5 px-1">
          <div className="content container">

            <div className="row">
              <div className="col">
                <h2 className="text-center mb-5">PRICE DETAILS</h2>
              </div>
            </div>

            <div className="row justify-content-around">
              <div className="col-12 col-md-6 pb-5">
                <div className="card">
                  <div className="card-header text-center">
                    Pay Once
                  </div>

                  <div className="card-body text-center">
                    <h3>${Number.format(parseInt(item.vehicle_price))}</h3>
                    <p className="mb-4">Free Shipping</p>

                    <table>
                      <tbody>
                      <tr>
                        <td className="text-start">Vehicle Price</td>
                        <td className="text-end">${Number.format(parseInt(item.vehicle_price))}</td>
                      </tr>

                      <tr>
                        <td className="text-start">Tax, Title, Registration</td>
                        <td className="text-end">$0</td>
                      </tr>

                      <tr>
                        <td className="text-start">Shipping</td>
                        <td className="text-end">FREE</td>
                      </tr>

                      <tr>
                        <td className="text-start">Bogus Fees</td>
                        <td className="text-end">NEVER</td>
                      </tr>
                      </tbody>

                      <tfoot>
                      <tr>
                        <td className="text-start">Total Cash Price</td>
                        <td className="text-end">$49,995</td>
                      </tr>
                      </tfoot>
                    </table>

                  </div>
                </div>
              </div>

              <div className="col-12 col-md-6 pb-5">
                <div className="card">
                  <div className="card-header text-center">
                    Pay Monthly
                  </div>

                  <div className="card-body">

                    <div className="row mt-4 mb-4">
                      <div className="col">
                        <input type="text" className="form-control" placeholder="Down Payment" aria-label="Down Payment"/>
                      </div>
                    </div>

                    <div className="row mt-4">
                      <div className="col">
                        <input type="text" className="form-control" placeholder="Term" aria-label="Term"/>
                      </div>
                    </div>

                    <div className="row mt-4 mb-5">
                      <div className="col">
                        <input type="text" className="form-control" placeholder="APR" aria-label="APR"/>
                      </div>
                    </div>

                    <hr/>

                    <div className="text-center mt-4 mb-4">
                      <button type="submit" className="btn btn-info">Calculate payment</button>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section-warranty py-5 px-3" >
          <div className="container">
            <div className="row">
              <div className="col">
                <h2 className="text-center pb-4">ALL VEHICLE ARE INSPECTED AND SERVICED</h2>
                <p  className="text-center">Our service department services all makes and models.</p>
              </div>
            </div>
          </div>
        </div>

        { similars.length > 0 && <div className="section-similar py-5 px-1" >
          <div className="content container">
            <div className="row">
              <div className="col">
                <h2 className="text-center mb-5">Similar Vehicles</h2>
              </div>
            </div>

            <div className="row justify-content-around">
              { similars.map((vehicle, index) => {
                return <Similar key={index} car={vehicle}/>;
              }) }
            </div>
          </div>
        </div>}

        <div className="section-options px-1" >
          <div className="content container">
            <div className="row">
              <div className="col text-center">
                <a href="/inventory">
                  <img src={ require('../../assets/images/search-icon.png') }  alt="Back to search"/>

                  <span>
                  Back to search
                </span>
                </a>
              </div>

              <div className="col text-center">
                <a href="#">
                  <img src={ require('../../assets/images/financing-icon.png') } alt="Get financing"/>

                  <span>
                  Get financing
                </span>
                </a>
              </div>

              <div className="col text-center">
                <a href="#">
                  <img src={ require('../../assets/images/trade-in-icon.png') } alt="Get Trade-In Value"/>

                  <span>
                    Get Trade-In Value
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>

      </div>

      <Footer/>

    </>
  );
}

export default Car;
