import './Vehicle.scss';
import {useState} from "react";
import {ToastContainer,Toast} from "react-bootstrap";

function Vehicle({ data }) {
  const [show, setShow] = useState(false);

  const toggleShow = () => setShow(!show);

  let dollarUSLocale = Intl.NumberFormat('en-US');

  const getImage = (data) => {

    if (data !== null &&  typeof data[0] === "string")
      return data[0];

    return require('../../assets/images/img-not-available.jpg');
  }

  return (
    <>
      <div className="car col-12 col-md-6 col-lg-4 col-xl-3">
        <div className="card mb-4">
          <a href={ '/car/' + data.id } ><img src={ getImage(data.photos)  } className="card-img-top" alt="vehicle"/></a>

          <div className="card-body position-relative">

            <ToastContainer className="p-3" position="middle-center">
              <Toast show={show} onClose={toggleShow} bg='light'>
                <Toast.Header>
                  <strong className="me-auto">Warranty</strong>
                </Toast.Header>
                <Toast.Body>*Our cars come with extended warranty upon the customer choice</Toast.Body>
              </Toast>
            </ToastContainer>

            <h6>{ data.stock_number }</h6>
            <h5><a href="/car">{ data.year } { data.make } { data.model }</a></h5>
            <p>{ data.transmission } · { data.engine }</p>

            <div className="row">
              <div className="col-6 d-flex align-items-center">
                { data.vehicle_price
                  ? <h4>${ dollarUSLocale.format(data.vehicle_price) }</h4>
                  : <h4>Call For Price</h4>
                }
              </div>

              <div className="col-6 text-end">
                <div>
                  { data.doors } Doors
                </div>

                <div>
                  { dollarUSLocale.format(data.odometer) } miles
                </div>
              </div>
            </div>

            <hr/>

            <div className="d-flex justify-content-between actions">
              <button type="button" class="btn btn-outline-info btn-sm" data-bs-toggle="modal" data-bs-target="#myModal" data-bs-whatever="Confirm Availability" data-bs-href={`https://dwssecuredforms.dealercenter.net/ConfirmAvailability/index/2216728?themecolor=ff0000&amp;formtype=l&amp;frameId=dws_frame_0&amp;standalone=true&amp;ls=Schedule&amp;stocknumber=${data.stock_number}&amp;dcid=2216728`}>
								Confirm Availability</button>
              <a onClick={toggleShow} className="btn btn-outline-info btn-sm">Warranty</a>
              <button type="button" class="btn btn-outline-info btn-sm" data-bs-toggle="modal" data-bs-target="#myModal" data-bs-whatever="Test Drive" data-bs-href={`https://dwssecuredforms.dealercenter.net/ScheduleATestDrive/index/2216728?themecolor=ff0000&formtype=l&frameId=dws_frame_0&standalone=true&ls=Schedule&stocknumber=${data.stock_number}&dcid=2216728`}>
								Test Drive</button>
            </div>

          </div>
        </div>
      </div>


    </>
  );
}

export default Vehicle;
