import './Location.scss';
import {Wrapper, Status} from "@googlemaps/react-wrapper";
import {Children, cloneElement, isValidElement, useEffect, useRef, useState} from "react";
import {Spinner} from "react-bootstrap";

function Map({center, zoom, children}: {
  center: google.maps.LatLngLiteral;
  zoom: number;
}) {
  const ref = useRef(null);
  const [map, setMap] = useState();

  useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {
        center,
        zoom,
      }));
    }
  }, [ref, map]);

  return (<>
    <div ref={ref} id="map"/>
    {Children.map(children, (child) => {
      if (isValidElement(child)) {
        return cloneElement(child, {map});
      }
    })}
  </>);
}

const Marker = (options) => {
  const [marker, setMarker] = useState();

  useEffect(() => {
    if (!marker) {
      setMarker(new google.maps.Marker());
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  useEffect(() => {
    if (marker) {
      marker.setOptions(options);
    }
  }, [marker, options]);

  return null;
};

function Location() {

  const render = (status) => {
    switch (status) {
      case Status.LOADING:
        return <Spinner/>;
      case Status.FAILURE:
        return <div/>;
      case Status.SUCCESS:
        return <Map/>;
    }
  };

  return (
    <Wrapper apiKey={"AIzaSyChQ3fbbcNW8E6i6-RsgtY97F9jIRDlERo"} render={render}>
      <Map center={{lat: 29.893489, lng: -90.119036}} zoom={16}>
        <Marker position={{lat: 29.893489, lng: -90.119036}}/>
      </Map>
    </Wrapper>
  );
}

export default Location;
