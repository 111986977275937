import './Similar.scss';
import vehicle from "../../assets/images/vehicle.png";
import {useState} from "react";
import {ToastContainer,Toast} from "react-bootstrap";

function Similar({car}) {
  const [show, setShow] = useState(false);
  let Number = Intl.NumberFormat('en-US');

  const toggleShow = () => setShow(!show);

  return (
    <>
      <div className="col-12 col-md-4">
        <div className="card mb-4">
          <a href="/car"><img src={car.photos[0]} className="card-img-top" alt="vehicle"/></a>

          <div className="card-body position-relative">

            <ToastContainer className="p-3" position="middle-center">
              <Toast show={show} onClose={toggleShow} bg='light'>
                <Toast.Header>
                  <strong className="me-auto">Warranty</strong>
                </Toast.Header>
                <Toast.Body>*Our cars come with extended warranty upon the customer choice</Toast.Body>
              </Toast>
            </ToastContainer>

            <h6>{car.stock_number}</h6>
            <h5><a href="/car">{car.year} {car.make} {car.model}</a></h5>
            <p>{car.transmission} · {car.drivetrain}, {car.engine}</p>

            <div className="row">
              <div className="col-6 d-flex align-items-center">
                <h4>${Number.format(parseInt(car.vehicle_price))}</h4>
              </div>

              <div className="col-6 text-end">
                <div>
                  {car.doors} Doors
                </div>

                <div>
                  {Number.format(parseInt(car.odometer))} miles
                </div>
              </div>
            </div>

            <hr/>

            <div className="d-grid">
              <a href={ '/car/' + car.id } className="btn btn-outline-info">VIEW DETAILS</a>
            </div>

          </div>
        </div>
      </div>


    </>
  );
}

export default Similar;
