import './Warranty.scss';
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import aul from '../../assets/images/aul.svg';
import Location from "../../components/Location/Location";

function Warranty() {

  return (
    <>
      <Header/>

      <div className="page-warranty content-wrapper">
        <div className="header">
          <h2 className="text-center">WARRANTY</h2>
          <h1 className="text-center">AUL®</h1>
        </div>

        <div className="section-1 container">
          <div className="row justify-content-around">

            <div className="col-4 text-center">
              <img src={aul} width="650" height="404" alt="AUL"/>
            </div>

            <div className="col-4 d-flex align-items-center text-center">
              <div>
                <p>
                  CALL OR VISIT TODAY TO LEARN MORE ABOUT OUR AVAILABLE WARRANTIES
                </p>
              </div>
            </div>

          </div>
        </div>

        <div className="section-2 container">
          <div className="row justify-content-around">
            <div className="col-5">
              <div className="card">
                <div className="card-header text-center">
                  Store Hours
                </div>
                <div className="card-body text-center">
                  <div className="title mt-5">Monday-Satunday</div>
                  <div className="text mb-5">9:00 AM - 7:00 PM</div>
                  <div className="title">Sunday</div>
                  <div className="text mb-5">Closed</div>
                  <div className="featured">504-328-1445</div>
                  <div className="featured mb-5">gcautodeals@gmail.com</div>
                </div>
              </div>
            </div>

            <div className="col-5">
              <div className="card">
                <div className="card-header text-center">
                  Send a message
                </div>

                <div className="card-body">

                  <div className="row mt-4 mb-4">
                    <div className="col">
                      <input type="text" className="form-control" placeholder="First name" aria-label="First name"/>
                    </div>

                    <div className="col">
                      <input type="text" className="form-control" placeholder="Last name" aria-label="Last name"/>
                    </div>
                  </div>

                  <div className="row mb-4">
                    <div className="col">
                      <input type="text" className="form-control" placeholder="Email" aria-label="Email"/>
                    </div>
                    <div className="col">
                      <input type="text" className="form-control" placeholder="Phone" aria-label="Phone"/>
                    </div>
                  </div>

                  <div className="row mb-5">
                    <div className="col">
                      <textarea className="form-control" placeholder="Message" rows="3" />
                    </div>
                  </div>

                  <hr/>

                  <div className="text-center mt-4 mb-4">
                    <button type="submit" className="btn btn-info">Submit</button>
                  </div>

                  <p className="note mb-5">
                    By clicking "SUBMIT", I consent to be contacted by the dealer at any telephone number or Email I
                    provide, including, without limitation, communications sent via text message to my cell phone or
                    communications sent using an autodialer or prerecorded message. This acknowledgment constitutes my
                    written consent to receive such communications.
                    I have read and agree to the Privacy Policy of this dealer.
                  </p>

                </div>
              </div>
            </div>
          </div>
        </div>

        <Location/>
      </div>

      <Footer/>

    </>
  );
}

export default Warranty;
