import './Inventory.scss';
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import {useInventory} from "../../hooks/useInventory";
import {useEffect, useMemo, useState} from "react";
import Vehicle from "../../components/Vehicle/Vehicle";
import SearchForm from "../../components/SearchForm/SearchForm";
import SearchResult from "../../components/SearchResult/SearchResult";

function Inventory() {

  const {isLoading, inventory} = useInventory();
  const [search, setSearch] = useState('');
  const [year, setYear] = useState('');
  const [make, setMake] = useState('');
  const [drivetrain, setDrivetrain] = useState('');
  const [transmission, setTransmission] = useState('');
  const [mileages, setMileages] = useState('');
  const [cylinder, setCylinder] = useState('');
  const [options, setOptions] = useState({
    years: [],
    makes: [],
    drivetrains: [],
    transmissions: [],
    mileages: [],
    cylinders: []
  });
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentSort, setCurrentSort] = useState('');
  const [result, setResult] = useState([]);

  const firstPage = () => {
    window.scrollTo(0, 0)
    setCurrentPage(0);
  }

  const previousPage = () => {
    window.scrollTo(0, 0)
    setCurrentPage(currentPage - 12);
  }

  const nextPage = () => {
    window.scrollTo(0, 0)
    setCurrentPage(currentPage + 12);
  }

  const lastPage = () => {
    window.scrollTo(0, 0)
    let pages = Math.ceil(inventory.length / 12) - 1;

    setCurrentPage(pages * 12);
  }

  useEffect(() => {
    let cars = inventory;

    //region Obtener valores de filtros
    let yearsOpt = [];
    let makesOpt = [];
    let drivetrainsOpt = [];
    let mileagesOpt = [
      '0-10000',
      '10000-20000',
      '20000-30000',
      '30000-40000',
      '40000-50000',
      '50000-60000',
      '60000-70000',
      '70000-80000',
      '80000-90000',
      '90000-100000',
      '100000-110000',
      '110000-120000',
      '120000-130000',
      '130000-140000',
      '140000-150000',
      '150000-170000',
      '170000-180000',
      '180000-190000',
      '190000-220000',
      '220000-410000',
    ];
    let transmissionsOpt = [];
    let cylindersOpt = []

    for (const car of cars) {
      if (yearsOpt.indexOf(car.year) === -1)
        yearsOpt.push(car.year);

      if (makesOpt.indexOf(car.make) === -1)
        makesOpt.push(car.make);

      if (drivetrainsOpt.indexOf(car.drivetrain) === -1)
        drivetrainsOpt.push(car.drivetrain);

      if (transmissionsOpt.indexOf(car.transmission) === -1)
        transmissionsOpt.push(car.transmission);

      if (cylindersOpt.indexOf(car.cylinder) === -1)
        cylindersOpt.push(car.cylinder);
    }

    yearsOpt.sort();
    makesOpt.sort();
    drivetrainsOpt.sort();
    transmissionsOpt.sort()
    cylindersOpt.sort();
    //endregion

    //region filtros
    if (search.length !== 0){
      cars = cars.filter( car => car.description.toLowerCase().includes( search.toLowerCase() ) );
    }

    if (year !== ''){
      cars = cars.filter( car => car.year === year );
    }

    if (make !== ''){
      cars = cars.filter( car => car.make === make );
    }

    if (drivetrain !== ''){
      cars = cars.filter( car => car.drivetrain === drivetrain );
    }

    if (transmission !== ''){
      cars = cars.filter( car => car.transmission === transmission );
    }

    if (mileages !== ''){
      cars = cars.filter( car => {
        const value = parseInt(car.odometer);
        const [min, max] = mileages.split('-')
        console.log(value);
        console.log(min);
        console.log(max);
        return value > parseInt(min) &&  value < parseInt(max);
      } );
    }

    if (cylinder !== ''){
      cars = cars.filter( car => parseInt(car.cylinder) === parseInt(cylinder) );
    }
    //endregion

    // Ordenar Resualados
    switch (currentSort) {
      case 'price_asc':
        cars.sort((a, b) => a.vehicle_price - b.vehicle_price);
        break

      case 'price_desc':
        cars.sort((a, b) => b.vehicle_price - a.vehicle_price);
        break

      case 'miles_asc':
        cars.sort((a, b) => a.odometer - b.odometer);
        break

      case 'miles_desc':
        cars.sort((a, b) => b.odometer - a.odometer);
        break

      case 'year_asc':
        cars.sort((a, b) => a.year - b.year);
        break

      case 'year_desc':
        cars.sort((a, b) => a.year - b.year);
        break

      case 'make_asc':
        cars.sort((a, b) => {
          if (a.make.toLowerCase() < b.make.toLowerCase()) return -1;

          if (a.make.toLowerCase() > b.make.toLowerCase()) return 1;

          return 0;
        });
        break

      case 'make_desc':
        cars.sort((a, b) => {
          if (a.make.toLowerCase() < b.make.toLowerCase()) return 1;

          if (a.make.toLowerCase() > b.make.toLowerCase()) return -1;

          return 0;
        });
        break
    }

    setOptions({
      years: yearsOpt,
      makes: makesOpt,
      drivetrains: drivetrainsOpt,
      mileages: mileagesOpt,
      transmissions: transmissionsOpt,
      cylinders: cylindersOpt,
    });
    setTotal(cars.length)
    setResult( cars.slice(currentPage, currentPage + 12));

  }, [currentPage, currentSort, cylinder, drivetrain, inventory, make, mileages, search, transmission, year]);

  if (isLoading) {
    return (<h1 style={{textAlign: "center"}}>Loading</h1>);
  }

  const scriptTag = document.createElement("script");
        scriptTag.src = "/static/js/my_inventory_js.js";
        scriptTag.async = true;
        document.body.appendChild(scriptTag);

  return (<>
      <Header/>

      <div className="page-inventory content-wrapper">
        <div className="section-1 container pt-3">
          <h6>Used cars</h6>

          <div>
            <div className="row">
              <div className="col mb-3">
                <input
                  type="text"
                  className="form-control search-input"
                  placeholder="SearchResult makes, models or keywords"
                  aria-label="SearchResult"
                  value={ search }
                  onChange={ (event) => setSearch( event.target.value)  }
                />
              </div>
            </div>

            <div className="row">
              <div className="col-6 col-sm-6 col-md-4 col-lg-2 col-xl mb-3">
                <select
                  className="form-select"
                  aria-label="Year"
                  onChange={ (event) => setYear( event.target.value) }
                  value={year}
                >
                  <option value="">Year</option>
                  { options.years.map((item, index) => {
                    return <option key={ index } value={ item }>{ item }</option>
                  })}
                </select>
              </div>

              <div className="col-6 col-sm-6 col-md-4 col-lg-2 col-xxl mb-3">
                <select
                  className="form-select" aria-label="Make"
                  onChange={ (event) => setMake( event.target.value) }
                  value={make}
                >
                  <option>Make</option>
                  { options.makes.map((item, index) => {
                    return <option key={ index } value={ item } >{ item }</option>
                  })}
                </select>
              </div>

              <div className="col-6 col-sm-6 col-md-4 col-lg-2 col-xxl mb-3">
                <select
                  className="form-select"
                  aria-label="Drivetrain"
                  onChange={ (event) => setDrivetrain( event.target.value) }
                  value={drivetrain}
                >
                  <option value="">Drivetrain</option>
                  { options.drivetrains.map((item, index) => {
                    return <option key={ index } value={ item }>{ item }</option>
                  })}
                </select>
              </div>

              <div className="col-6 col-sm-6 col-md-4 col-lg-2 col-xxl mb-3">
                <select
                  className="form-select"
                  aria-label="Transmission"
                  onChange={ (event) => setTransmission( event.target.value) }
                  value={transmission}
                >
                  <option value="">Transmission</option>
                  { options.transmissions.map((item, index) => {
                    return <option key={ index } value={ item }>{ item }</option>
                  })}
                </select>
              </div>

              <div className="col-6 col-sm-6 col-md-4 col-lg-2 col-xxl mb-3">
                <select
                  className="form-select"
                  aria-label="Mileage"
                  onChange={ (event) => setMileages( event.target.value) }
                  value={mileages}
                >
                  <option value="" >Mileage</option>
                  { options.mileages.map((item, index) => {
                    return <option key={ index } value={ item }>{ item }</option>
                  })}
                </select>
              </div>

              <div className="col col-xs-12 col-sm-6 col-md-4 col-lg-2 col-xxl mb-3">
                <select
                  className="form-select"
                  aria-label="Cylinder"
                  onChange={ (event) => setCylinder( event.target.value) }
                  value={cylinder}
                >
                  <option value="">Cylinder</option>
                  { options.cylinders.map((item, index) => {
                    return <option key={ index } value={ item }>{ item }</option>
                  })}
                </select>
              </div>
            </div>
          </div>
        </div>

        <SearchResult
          results={result}
          total={total}
          currentPage={currentPage}
          currentSort={currentSort}
          setCurrentSort={setCurrentSort}
          firstPage={firstPage}
          previousPage={previousPage}
          nextPage={nextPage}
          lastPage={lastPage}
        />
      </div>

<div class="modal fade" id="myModal" tabindex="-1" aria-labelledby="myModal" aria-hidden="true">
	<div class="modal-dialog modal-lg">
		<div class="modal-content">
			<div class="modal-header">
				<h4 class="modal-title fs-5" id="myModalLabel">Title</h4>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
			</div>
			<div class="modal-body">
				<iframe id="myIframe" src=""></iframe>
			</div>
		</div>
	</div>
</div>

      <Footer/>
    </>);
}

export default Inventory;
