import Slider from "react-slick";
import bannerPrimary from '../../assets/images/bannerPrimaryBg.png';
import bannerTertiaryLeft from '../../assets/images/bannerTertiaryLeftBg.png';
import bannerTertiaryRight from '../../assets/images/bannerTertiaryRightBg.png';
import workshop from '../../assets/images/workshop-bg.png';
import './Home.scss';
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Location from "../../components/Location/Location";
import Marks from "../../components/Marks/Marks";

function Home() {

  const sliderXsSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1 ,
    slidesToScroll: 1,
    arrows: false,
  };

  const sliderMdSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2 ,
    slidesToScroll: 1,
    arrows: false,
  };

  const sliderLgSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3 ,
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <>
      <Header/>

      <div className="page-home content-wrapper">

        <div className="main-banner container d-flex align-items-center"
          style={{backgroundImage: `url(${bannerPrimary})`}}
        >
          <div className="row">
            <div className="col">
              <h1>
                To enjoy <br/>
                with the family
              </h1>
            </div>
          </div>
        </div>

        <div className="section-1 container">
          <div className="row py-3 align-items-center">
            <div className="col-12 col-lg-5 col-xl-6 text-center text-lg-end">
              <h3>Are you looking for something special?</h3>
              <div>We have a large inventory in constant renewal</div>
            </div>

            <div className="col-12 col-lg-7 col-xl-6 d-flex flex-column flex-md-row align-items-center justify-content-center pt-2 pt-lg-0">
              <a href="/inventory" className="btn btn-info">Search in our Inventary</a>
              <div><span className="or">or</span></div>
              <a href="/finder" className="btn btn-outline-info">Use our car Finder</a>
            </div>
          </div>
        </div>

        <div className="section-payment container"  >
          <div className="row py-3 align-items-center">
              <div className="col-12 col-md-10 text-center text-md-end">
                  <h3>If you already purchased a vehicle and wish to make your monthly payment, pay here now</h3>
              </div>

              <div className="col-12 col-md-2 d-flex align-items-center justify-content-center pt-3 justify-content-md-start pt-md-0">
                <form name="PrePage" method="post" action="https://Simplecheckout.authorize.net/payment/CatalogPayment.aspx">
                  <input type="hidden" name="LinkId" value="4e36dc79-62d9-4b47-a0e2-5ac06ef98e8c"/>
                  <input type="submit" className="btn btn-light" value="Pay now"/>
                </form>
              </div>
          </div>
        </div>

        <div className="section-2 py-2 container">

          <div className="row gx-2">

            <div className="banner-tertiary-left col-12 col-md-6">

              <div style={{backgroundImage: `url(${bannerTertiaryLeft})`}}>
                <div>
                  <div className="legend">

                    <h2>
                      Apply <br/>
                      Online
                    </h2>

                    <p>Complete the Application Here!</p>

                  </div>
                </div>

                <a href="/apply" className="btn btn-light">Apply Now</a>

              </div>
            </div>

            <div className="banner-tertiary-right col-12 pt-2 col-md-6 pt-md-0">
              <div style={{backgroundImage: `url(${bannerTertiaryRight})`}}>
                <div className="legend">

                  <h2>
                    Westbank<br/>
                    Collision Center
                  </h2>

                  <p>Schedule Service Today!</p>

                </div>

                <a href="/services" className="btn btn-info">Schedule Here</a>

              </div>
            </div>

          </div>

        </div>

        <div className="section-3 container pb-5 " style={{backgroundImage: `url(${workshop})`}}>
          <div className="section-3-content">
            <div className="legend mx-2 mx-lg-0 mx-3">
              <h5>WELCOME TO</h5>
              <h2>G & C AUTO SALES & SERVICE</h2>
            </div>

            <div className="d-md-none">
              <Slider {...sliderXsSettings}>
                <div className="px-2">
                  <div className="card">
                    <div className="card-body">

                      <div className="card-icon">
                        <i className="fas fa-toolbox fa-2xl"/>
                      </div>

                      <h5 className="card-title">Dedication</h5>
                      <p className="card-text">
                        We are dedicated to providing the ultimate automobile buying experience.
                        We are your #1 source for buying a quality pre-owned vehicles.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="px-2">
                  <div className="card">
                    <div className="card-body">

                      <div className="card-icon">
                        <i className="fas fa-user-tie fa-2xl"/>
                      </div>

                      <h5 className="card-title">Experts</h5>
                      <p className="card-text">
                        We have extensive relationships in the dealer community allowing us to
                        purchase a wide variety of lease returns and new car trades at exceptional
                        values.
                        This enables us to pass along huge savings on the highest quality vehicles
                        of your choice.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="px-2">
                  <div className="card">
                    <div className="card-body">

                      <div className="card-icon">
                        <i className="fas fa-hand-holding-dollar fa-2xl"/>
                      </div>

                      <h5 className="card-title">financing</h5>
                      <p className="card-text">
                        We offer a full array of financing options to meet your needs.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="px-2">
                  <div className="card">
                    <div className="card-body">

                      <div className="card-icon">
                        <i className="fas fa-location-dot fa-2xl"/>
                      </div>

                      <h5 className="card-title">Delivery or pick up</h5>
                      <p className="card-text">
                        Delivery straight to your door or pick up at our state of the art Carvana
                        Vending Machines!
                      </p>
                    </div>
                  </div>
                </div>

                <div className="px-2">
                  <div className="card">
                    <div className="card-body">

                      <div className="card-icon">
                        <i className="fas fa-calendar fa-2xl"/>
                      </div>

                      <h5 className="card-title">7 day test to own</h5>
                      <p className="card-text">
                        We give you 7 days to see if your car truly fits into your life.
                        If it doesn’t, simply return it.
                      </p>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>

            <div className="d-none d-md-block d-lg-none">
              <Slider {...sliderMdSettings}>
                <div className="px-2">
                  <div className="card">
                    <div className="card-body">

                      <div className="card-icon">
                        <i className="fas fa-toolbox fa-2xl"/>
                      </div>

                      <h5 className="card-title">Dedication</h5>
                      <p className="card-text">
                        We are dedicated to providing the ultimate automobile buying experience.
                        We are your #1 source for buying a quality pre-owned vehicles.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="px-2">
                  <div className="card">
                    <div className="card-body">

                      <div className="card-icon">
                        <i className="fas fa-user-tie fa-2xl"/>
                      </div>

                      <h5 className="card-title">Experts</h5>
                      <p className="card-text">
                        We have extensive relationships in the dealer community allowing us to
                        purchase a wide variety of lease returns and new car trades at exceptional
                        values.
                        This enables us to pass along huge savings on the highest quality vehicles
                        of your choice.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="px-2">
                  <div className="card">
                    <div className="card-body">

                      <div className="card-icon">
                        <i className="fas fa-hand-holding-dollar fa-2xl"/>
                      </div>

                      <h5 className="card-title">financing</h5>
                      <p className="card-text">
                        We offer a full array of financing options to meet your needs.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="px-2">
                  <div className="card">
                    <div className="card-body">

                      <div className="card-icon">
                        <i className="fas fa-location-dot fa-2xl"/>
                      </div>

                      <h5 className="card-title">Delivery or pick up</h5>
                      <p className="card-text">
                        Delivery straight to your door or pick up at our state of the art Carvana
                        Vending Machines!
                      </p>
                    </div>
                  </div>
                </div>

                <div className="px-2">
                  <div className="card">
                    <div className="card-body">

                      <div className="card-icon">
                        <i className="fas fa-calendar fa-2xl"/>
                      </div>

                      <h5 className="card-title">7 day test to own</h5>
                      <p className="card-text">
                        We give you 7 days to see if your car truly fits into your life.
                        If it doesn’t, simply return it.
                      </p>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>

            <div className="d-none d-lg-block d-xl-none">
              <Slider {...sliderLgSettings}>
                <div className="px-2">
                  <div className="card">
                    <div className="card-body">

                      <div className="card-icon">
                        <i className="fas fa-toolbox fa-2xl"/>
                      </div>

                      <h5 className="card-title">Dedication</h5>
                      <p className="card-text">
                        We are dedicated to providing the ultimate automobile buying experience.
                        We are your #1 source for buying a quality pre-owned vehicles.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="px-2">
                  <div className="card">
                    <div className="card-body">

                      <div className="card-icon">
                        <i className="fas fa-user-tie fa-2xl"/>
                      </div>

                      <h5 className="card-title">Experts</h5>
                      <p className="card-text">
                        We have extensive relationships in the dealer community allowing us to
                        purchase a wide variety of lease returns and new car trades at exceptional
                        values.
                        This enables us to pass along huge savings on the highest quality vehicles
                        of your choice.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="px-2">
                  <div className="card">
                    <div className="card-body">

                      <div className="card-icon">
                        <i className="fas fa-hand-holding-dollar fa-2xl"/>
                      </div>

                      <h5 className="card-title">financing</h5>
                      <p className="card-text">
                        We offer a full array of financing options to meet your needs.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="px-2">
                  <div className="card">
                    <div className="card-body">

                      <div className="card-icon">
                        <i className="fas fa-location-dot fa-2xl"/>
                      </div>

                      <h5 className="card-title">Delivery or pick up</h5>
                      <p className="card-text">
                        Delivery straight to your door or pick up at our state of the art Carvana
                        Vending Machines!
                      </p>
                    </div>
                  </div>
                </div>

                <div className="px-2">
                  <div className="card">
                    <div className="card-body">

                      <div className="card-icon">
                        <i className="fas fa-calendar fa-2xl"/>
                      </div>

                      <h5 className="card-title">7 day test to own</h5>
                      <p className="card-text">
                        We give you 7 days to see if your car truly fits into your life.
                        If it doesn’t, simply return it.
                      </p>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>

            <div className="px-3 d-none d-xl-block">
              <div className="row">
                <div className="col">
                  <div className="card">
                    <div className="card-body">

                      <div className="card-icon">
                        <i className="fas fa-toolbox fa-2xl"/>
                      </div>

                      <h5 className="card-title">Dedication</h5>
                      <p className="card-text">
                        We are dedicated to providing the ultimate automobile buying experience.
                        We are your #1 source for buying a quality pre-owned vehicles.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col">
                  <div className="card">
                    <div className="card-body">

                      <div className="card-icon">
                        <i className="fas fa-user-tie fa-2xl"/>
                      </div>

                      <h5 className="card-title">Experts</h5>
                      <p className="card-text">
                        We have extensive relationships in the dealer community allowing us to
                        purchase a wide variety of lease returns and new car trades at exceptional
                        values.
                        This enables us to pass along huge savings on the highest quality vehicles
                        of your choice.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col">
                  <div className="card">
                    <div className="card-body">

                      <div className="card-icon">
                        <i className="fas fa-hand-holding-dollar fa-2xl"/>
                      </div>

                      <h5 className="card-title">financing</h5>
                      <p className="card-text">
                        We offer a full array of financing options to meet your needs.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col">
                  <div className="card">
                    <div className="card-body">

                      <div className="card-icon">
                        <i className="fas fa-location-dot fa-2xl"/>
                      </div>

                      <h5 className="card-title">Delivery or pick up</h5>
                      <p className="card-text">
                        Delivery straight to your door or pick up at our state of the art Carvana
                        Vending Machines!
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col">
                  <div className="card">
                    <div className="card-body">

                      <div className="card-icon">
                        <i className="fas fa-calendar fa-2xl"/>
                      </div>

                      <h5 className="card-title">7 day test to own</h5>
                      <p className="card-text">
                        We give you 7 days to see if your car truly fits into your life.
                        If it doesn’t, simply return it.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Marks/>

        <Location/>

      </div>

      <Footer/>

    </>
  );
}

export default Home;
