import './Contact.scss';
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Location from "../../components/Location/Location";

function Contact() {

  return (
    <>
      <Header/>

      <div className="page-contact content-wrapper">
        <div className="header ">
          <h2 className="text-center">Contact</h2>
          <h1 className="text-center">G&C Auto Sales & Service</h1>
        </div>

        <div className="section-1 container">
          <div className="row justify-content-around pt-5">
            <div className="col-12 col-md-6 col-xl-5 mb-5 order-1 order-md-0">
              <h2 className="text-center mb-5">Send a message</h2>

              <iframe title="contact us form" className="iframe-touch-scroll dws_frame_0" id="dws_frame_0"
                data-src="https://dwssecuredforms.dealercenter.net/ContactForm/index/2216728?themecolor=ff0000&formtype=l&frameId=dws_frame_0&standalone=true&ls=OurWebsite"
                src="https://dwssecuredforms.dealercenter.net/ContactForm/index/2216728?themecolor=ff0000&formtype=l&frameId=dws_frame_0&standalone=true&ls=OurWebsite"
                style={{ height: '575px', width: '100%' }}
              />

            </div>

            <div className="col-12 col-md-6 col-xl-5 mb-5 order-0 order-md-1">

                <div className="icon mb-2">
                  <i className="fa-solid fa-location-dot fa-2xl"/>
                </div>

                <div className="text-center">
                  <div className="featured">6595 Westbank <br/>
                    Expy Marrero, LA 70072
                  </div>
                  <div className="featured">504-328-1445</div>
                  <div className="featured mb-5">gcautodeals@gmail.com</div>
                  <div className="title mt-5">Monday-Satunday</div>
                  <div className="text">9:00 AM - 7:00 PM</div>
                  <div className="title">Sunday</div>
                  <div className="text">Closed</div>
                </div>

            </div>
          </div>
        </div>

        <Location/>
      </div>

      <Footer/>

    </>
  );
}

export default Contact;
