import './MainMenu.scss';
import logo from "../../assets/images/logo.svg";

function MainMenu() {
    return (
      <nav className="main-menu navbar navbar-expand-lg navbar-light bg-white sticky-top">
        <div className="container">
          <a className="navbar-brand" href="/">
            <img src={logo} alt="Logo" className="me-2" />
            <span className="d-none d-sm-inline">Auto Sale & Services</span>
          </a>

          <button className="navbar-toggler"
                  type="button" data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse"  id="navbarSupportedContent">

            <ul className="navbar-nav">

              <li className="nav-item">
                <a className="nav-link" href="/inventory">Inventory</a>
              </li>

              <li className="nav-item">
                <a className="nav-link" href="/finder">Car Finder</a>
              </li>

              <li className="nav-item">
                <a className="nav-link" href="/apply">Apply Online</a>
              </li>

              <li className="nav-item">
                <a className="nav-link" href="/services">Mechanic Services & Collision
</a>
              </li>

              <li className="nav-item d-lg-none">
                <a className="nav-link" href="/about">About</a>
              </li>

              <li className="nav-item d-lg-none">
                <a className="nav-link" href="/contact">Contact Us</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
  );
}

export default MainMenu;
