import './About.scss';
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import cars from '../../assets/images/about-cars.png';
import front from '../../assets/images/about-front.png';
import workshop from '../../assets/images/about-workshop.png';

function About() {

  return (
    <>
      <Header/>

      <div className="page-about content-wrapper">
        <div className="header">
          <h2 className="text-center">About</h2>
          <h1 className="text-center">G&C AUTO SALES & SERVICES</h1>
        </div>

        <div className="section-1 container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-6 col-xl-4 d-flex align-items-center">
              <div>
                <h2 className="text-center text-md-start">More than cars</h2>

                <p>With years of experience serving the area, our dealership is dedicated to offering high-quality,
                  pre-owned vehicles to our customers.</p>
                <p>
                  From the moment you walk through our door, we’re committed to providing you with a great car-buying
                  experience. With our skilled sales staff and financing options,
                  we’ll help you get the vehicle you want, at the great price you deserve.
                </p>
              </div>
            </div>

            <div className="col-12 col-md-6 col-xl-4 text-center">
              <img src={workshop} className="img-fluid" alt="Workshop"/>
            </div>
          </div>
        </div>

        <div className="section-2 container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-6 col-xl-4 text-center order-1 order-md-0">
              <img src={front} className="img-fluid" alt="Front"/>
            </div>

            <div className="col-12 col-md-6 col-xl-4 d-flex align-items-center order-0 order-md-1">
              <div>
                <h2 className="text-center text-md-start">More than cars</h2>

                <p>With years of experience serving the area, our dealership is dedicated to offering high-quality,
                  pre-owned vehicles to our customers.</p>
                <p>
                  From the moment you walk through our door, we’re committed to providing you with a great car-buying
                  experience. With our skilled sales staff and financing options,
                  we’ll help you get the vehicle you want, at the great price you deserve.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="section-3 container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-6 col-xl-4 d-flex align-items-center">
              <div>
                <h2 className="text-center text-md-start">More than cars</h2>

                <p>With years of experience serving the area, our dealership is dedicated to offering high-quality,
                  pre-owned vehicles to our customers.</p>
                <p>
                  From the moment you walk through our door, we’re committed to providing you with a great car-buying
                  experience. With our skilled sales staff and financing options,
                  we’ll help you get the vehicle you want, at the great price you deserve.
                </p>
              </div>
            </div>

            <div className="col-12 col-md-6 col-xl-4 text-center">
              <img src={cars} className="img-fluid" alt="Cars"/>
            </div>
          </div>
        </div>

        <div className="section-4 container">
          <div className="row py-3 align-items-center">
            <div className="col-12 col-lg-5 col-xl-6 text-center text-lg-end">
              <h3>Are you looking for something special?</h3>
              <div>We have a large inventory in constant renewal</div>
            </div>

            <div className="col-12 col-lg-7 col-xl-6 d-flex flex-column flex-md-row align-items-center justify-content-center pt-2 pt-lg-0">
              <a href="/inventory" className="btn btn-info">Search in our Inventary</a>
              <div><span className="or">or</span></div>
              <a href="/finder" className="btn btn-outline-info">Use our car Finder</a>
            </div>
          </div>
        </div>
      </div>

      <Footer/>

    </>
  );
}

export default About;
