import './Apply.scss';
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

function Apply() {

  return (
    <>
      <Header/>

      <div className="page-apply content-wrapper">
        <div className="section-1 container pb-5">
          <h2 className="text-center">APPLY ONLINE</h2>

          <div className="row justify-content-center">
            <div className="col-12 col-xxl-10">
              <div id="content" className="p-3 p-md-4 p-xl-5">
                <iframe title="contact us form" className="iframe-touch-scroll dws_frame_6"
                  data-src="https://dwssecuredforms.dealercenter.net/CreditApplication/index/2216728?themecolor=ff0000&formtype=l&frameId=dws_frame_0&standalone=true&ls=Our%20Website"
                  src="https://dwssecuredforms.dealercenter.net/CreditApplication/index/2216728?themecolor=ff0000&formtype=l&frameId=dws_frame_0&standalone=true&ls=Our%20Website"
                  style={{ height: '1240px', width: '100%' }}
                />
              </div>
            </div>
          </div>

        </div>

        <div className="section-2 container pt-6">
          <div className="row justify-content-around">
            <div className="col-12 col-md-6 col-xxl-5 pb-6">
              <h2 className="text-center mb-5">Questions?</h2>
              <p className="text-center">Speak with one of our professional staff members to get quick answers to your questions.</p>

              <iframe title="contact us form" className="iframe-touch-scroll dws_frame_6"
                data-src="https://dwssecuredforms.dealercenter.net/ContactForm/index/2216728?themecolor=ff0000&formtype=l&frameId=dws_frame_0&standalone=true&ls=OurWebsite"
                src="https://dwssecuredforms.dealercenter.net/ContactForm/index/2216728?themecolor=ff0000&formtype=l&frameId=dws_frame_0&standalone=true&ls=OurWebsite"
                style={{ height: '575px', width: '100%' }}
              />

            </div>

            <div className="col-12 col-md-6 col-xxl-5 d-flex align-items-center justify-content-center pb-6">
              <div className="text-center">
                <div className="featured">
                  504-328-1445<br/>
                  6595 Westbank <br/>
                  Expy Marrero, LA 70072
                </div>

                <div className="title mt-5">Monday-Satunday</div>
                <div className="text">9:00 AM - 7:00 PM</div>
                <div className="title">Sunday</div>
                <div className="text">Closed</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer/>

    </>
  );
}

export default Apply;
