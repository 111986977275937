import './Policy.scss';
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

function Policy() {

  return (
    <>
      <Header/>

      <div className="page-policy content-wrapper">
        <div className="header">
          <h1 className="text-center">Privacy Policy</h1>
        </div>

        <div className="section-1 container">
          <div className="row justify-content-center">
            <div className="col-10">

                <h2>CONSUMER PRIVACY STATEMENT</h2>
                <p>Our Dealership, Is serious about keeping your personal information private and secure. This notice of
                  our privacy policy explains how we use and protect your information. This form constitutes a legal
                  notification and once accepted by you, authorizes this dealership to use this information to assist
                  you in acquiring the products and services you seek. PLEASE READ THIS AUTHORIZATION AND PRIVACY
                  STATEMENT CAREFULLY BEFORE YOU ACCEPT.</p>

                <h2>WHERE WE GET INFORMATION ABOUT YOU</h2>
                <p>The information we get about you comes from different sources and may include:</p>

                <p>Information you give to us on applications or other forms, which may include your name, address,
                  drivers license number, employment history, income, telephone numbers, e-mail addresses and Social
                  Security number.</p>

                <p>Representations you make to us, such as your employment history, account balances, payment histories
                  and your relationships with other persons or entities you have listed as references.</p>

                <p>Information from your transactions with us, our affiliated companies or our business partners who may
                  provide you other related services.</p>

                <p>Information we receive from consumer credit reporting agencies to confirm, verify or add to facts you
                  provide us on applications or other forms. Data collected by such a reporting agency may be kept by
                  them and later shared with others who are entitled to use these reports. That personal information
                  gathered by consumer credit reporting agencies will be provided to others only as allowed by federal
                  and state Fair Credit reporting laws.</p>

                <p>Information we receive from your insurance agent or broker to confirm the presence of applicable
                  insurance coverages required under an installment payment agreement.</p>

                <h2>OUR DEALERSHIP DOES NOT SELL OR RENT INFORMATION ABOUT YOU TO OTHERS.</h2>
                <p>In order to conduct our business and offer you the products and/or services that you may want, we may
                  share your information within our company or affiliated companies (companies related by common
                  ownership). The law allows us to share your financial information solely for the purpose of providing
                  you the products and services you seek from this dealership. The law allows us to share your financial
                  information with our affiliates or third parties outside of our dealership in order to service, market
                  or underwrite our products and services to you. We may also share your information with financial
                  institutions we have entered into a written contract with in order to provide you with financial
                  products. We do not disclose your information to anyone else unless allowed or required by law. You
                  cannot prevent these disclosures. You can limit our sharing of your personal information with
                  affiliated and non-affiliated third parties to market their products to you. By signing this form, you
                  are authorizing this dealership to share your nonpublic personal information with affiliated and/or
                  non affiliated third parties that may have products or services that may be of value to you. You may
                  refuse to allow us to share information with affiliated and non-affiliated and non-affiliated
                  organization in order to market products and services other than ours; however, in doing so, you may
                  not receive offer for other products and services that may be of value to you. If it is your intent to
                  issue such a refusal (also known as an opt-out).</p>

                <h2>HOW WE PROTECT ANY NON-PUBLIC PERSONAL INFORMATION WE COLLECT ABOUT YOU.</h2>
                <p>We maintain physical, administrative, procedural and technical safeguards to protect this personal
                  information from unauthorized access.</p>

                <p>Access to your personal information is limited only to those who have a business need to do so. We
                  also expect our vendors, affiliated companies, third parties, insurance agents and others to maintain
                  a high regard for privacy and to safeguard all customer information.</p>

                <p>In the event the security of your information is breeched, we will notify you once the breech is
                  brought to our attention.</p>

                <p>We follow the laws of the state within which this dealership is domiciled when that law provided you
                  more protection than federal law.</p>

                <p>Authorization to share information with both affiliated and non-affiliated organizations:</p>

                <p>I acknowledge that I have reviewed and accepted the terms and conditions set forth in this privacy
                  statement.</p>
            </div>
          </div>
        </div>
      </div>

      <Footer/>

    </>
  );
}

export default Policy;
