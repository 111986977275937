import './Finder.scss';
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

function Finder() {

  return (
    <>
      <Header/>

      <div className="page-finder content-wrapper">
        <div className="section-1 p-2 p-lg-5">
          <div className="row">
            <div className="col-12 col-lg-5 d-flex align-items-center">
              <div id="titles" className="">
                <h2 className="text-center">CAR FINDER</h2>
                <p>
                Don’t see the car you are looking for?
                We can help you find it!
                Just fill out the form below and we’ll help you look
                for the car you want.
              </p>
              </div>
            </div>

            <div className="col-12 col-lg-7">
              <div id="content" className="p-lg-5 p-3">
                <iframe title="car finder form" className="iframe-touch-scroll dws_frame_3"
                  data-src="https://dwssecuredforms.dealercenter.net/CarFinder/index/2216728?themecolor=949494&formtype=l&frameId=dws_frame_0&standalone=true&ls=Our%20Website"
                  src="https://dwssecuredforms.dealercenter.net/CarFinder/index/2216728?themecolor=ff0000&formtype=l&frameId=dws_frame_0&standalone=true&ls=OurWebsite"
                  style={{ height: '530px', width: '100%' }}
                />
              </div>
            </div>
          </div>

        </div>

        <div className="section-2 container pt-6">
          <div className="row justify-content-around">
            <div className="col-12 col-lg-5 pb-6">

              <h2 className="text-center mb-5">Questions?</h2>

              <p className="text-center">Speak with one of our professional staff members to get quick answers to your questions.</p>

              <iframe title="contact us form" className="iframe-touch-scroll dws_frame_6"
                data-src="https://dwssecuredforms.dealercenter.net/ContactForm/index/2216728?themecolor=ff0000&formtype=l&frameId=dws_frame_0&standalone=true&ls=OurWebsite"
                src="https://dwssecuredforms.dealercenter.net/ContactForm/index/2216728?themecolor=ff0000&formtype=l&frameId=dws_frame_0&standalone=true&ls=OurWebsite"
                style={{ height: '575px', width: '100%' }}
              />

            </div>

            <div className="col-12 col-lg-5 d-flex align-items-center justify-content-center pb-6">
              <div className="text-center">
                <div className="featured">
                  504-328-1445<br/>
                  6595 Westbank <br/>
                  Expy Marrero, LA 70072
                </div>

                <div className="title mt-5">Monday-Satunday</div>
                <div className="text">9:00 AM - 7:00 PM</div>
                <div className="title">Sunday</div>
                <div className="text">Closed</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer/>

    </>
  );
}

export default Finder;
