import './Services.scss';
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import insurance from '../../assets/images/insurance.png';
import accident from '../../assets/images/accident.png';

function Services() {

  return (
    <>
      <Header/>

      <div className="page-services content-wrapper">
        <div className="header">
          <h1 className="text-center">Mechanic Services & Collision</h1>

          <p>
            After you've been in an accident, you deserve to be treated with compassion and respect as you recover from your unfortunate ordeal. 
            Your safety is everything to us, and we're here to treat you like the valued guest you are at every stage in your vehicle's restoration process. 
            Our technicians and mechanics will restore your car until it looks and drives like a new model again. 
            Because we're a comprehensive collision center, we can perform any kind of service or repair to fulfill the highest manufacturing safety and security standards.
          </p>

          <p>
            Schedule your service today by using the form below.
          </p>
        </div>

        <div className="section-1 container">
          <div className="icon-wrapper">
            <div className="icon">
              <i className="fa-solid fa-calendar fa-2xl"/>
            </div>
          </div>

          <div className="row justify-content-around">
            <div className="col-12">

              <iframe title="schedule service form" className="iframe-touch-scroll dws_frame_3" id="dws_frame_3"
                data-src="https://dwssecuredforms.dealercenter.net/ScheduleServiceAppointment/index/2216728?themecolor=ff0000&formtype=l&frameId=dws_frame_3&standalone=true&ls=Schedule"
                src="https://dwssecuredforms.dealercenter.net/ScheduleServiceAppointment/index/2216728?themecolor=ff0000&formtype=l&frameId=dws_frame_3&standalone=true&ls=Schedule"
                style={{ height: '475px', width: '100%' }}
              />

            </div>
          </div>
        </div>

        <div className="section-2 pt-5 container">
          <div className="row justify-content-around">
            <div className="col-12 col-md-6 col-xxl-5 pb-5">
              <div className="text-center pb-3">
                <img src={accident} className="img-fluid" alt="Accident"/>
              </div>


              <h3 className="text-center mb-5">Accident Repair process</h3>

              <p className="text-center">
                Hello Valued Customer,
              </p>

              <p className="text-center">
                Did you know we give free estimates for Collision, Towing and Dent Repair? Don’t delay! Fix your car Today, and Drive with Pride.
              </p>

              <p className="text-center">
                For 19 years we have been repairing Automobiles. We accept all Major Insurance companies, and Cash Payments, and are familiar with deductibles,
                to help you, with no out of pocket cash…Now we’re talking! Call today, and set up an appointment. 
                For any shop related issues use this special code for additional savings: Spring1044
              </p>

              <div className="row">
                  <div className="col-12 col-md-6">
                    <i class="fa-solid fa-check"></i> Free Estimates
                  </div>

                  <div className="col-12 col-md-6">
                    <i class="fa-solid fa-check"></i> Insurance Appraisals from all Insurance Companies
                  </div>

                  <div className="col-12 col-md-6">
                    <i class="fa-solid fa-check"></i> Towing
                  </div>

                  <div className="col-12 col-md-6">
                    <i class="fa-solid fa-check"></i> Collision/Accident Repair
                  </div>
                  
                  <div className="col-12 col-md-6">
                    <i class="fa-solid fa-check"></i> Collision Center Specials
                  </div>

                  <div className="col-12 col-md-6">
                    <i class="fa-solid fa-check"></i> Other Repairs
                  </div>
              </div>

            </div>

            <div className="col-12 col-md-6 col-xxl-5 pb-5">
              
            <div className="text-center pb-3">
                <img src={insurance} className="img-fluid" alt="Insurance"/>
              </div>

              <h3 className="text-center mb-5">G&C Insurance Staff for Collision & Repair</h3>

              <p className="text-center">
                G&C Insurance Staff for Collision & Repair
              </p>
              
              <p className="text-center">
                When dealing with insurance companies, we have you covered! G&C has over 20 years of experience to get your claim right, 
                and we do work with insurance companies to make sure that your claim is submitted and processed. 
                We want to make sure you get paid in the fastest time possible for the customers convenience.
              </p>

              <p className="text-center">
                Unseen damages? We consult with insurance and follow up with supplement billing to cover unseen damages, and save you extra out of pocket expenses. 
                And if extra time is needed, we’ll help with the cost of the rental car, by supplying you with the paperwork to cover it!
              </p>

              <div className="row">
                  <div className="col-12 col-md-6">
                    <i class="fa-solid fa-check"></i> Free Estimates
                  </div>

                  <div className="col-12 col-md-6">
                    <i class="fa-solid fa-check"></i> USAA
                  </div>

                  <div className="col-12 col-md-6">
                    <i class="fa-solid fa-check"></i> Progressive
                  </div>

                  <div className="col-12 col-md-6">
                    <i class="fa-solid fa-check"></i> Omni Insurance
                  </div>
                  
                  <div className="col-12 col-md-6">
                    <i class="fa-solid fa-check"></i> AAA
                  </div>

                  <div className="col-12 col-md-6">
                    <i class="fa-solid fa-check"></i> Go Auto
                  </div>

                  <div className="col-12 col-md-6">
                    <i class="fa-solid fa-check"></i> GEICO
                  </div>

                  <div className="col-12 col-md-6">
                    <i class="fa-solid fa-check"></i> Insurance America Family
                  </div>

                  <div className="col-12 col-md-6">
                    <i class="fa-solid fa-check"></i> All State
                  </div>

                  <div className="col-12 col-md-6">
                    <i class="fa-solid fa-check"></i> State Farm
                  </div>

                  <div className="col-12 col-md-6">
                    <i class="fa-solid fa-check"></i> Liberty Mutual
                  </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <Footer/>

    </>
  );
}

export default Services;
