import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import './index.scss';
import Home from './pages/Home/Home';
import About from "./pages/About/About";
import Policy from "./pages/Policy/Policy";
import Inventory from "./pages/Inventary/Inventory";
import Car from "./pages/Car/Car";
import Warranty from "./pages/Warranty/Warranty";
import Services from "./pages/Services/Services";
import Contact from "./pages/Contact/Contact";
import Apply from "./pages/Apply/Apply";
import Finder from "./pages/Finder/Finder";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/about" element={<About/>}/>
                <Route path="/policy" element={<Policy/>}/>
                <Route path="/inventory" element={<Inventory/>}/>
                <Route path="/car/:id" element={<Car/>}/>
                <Route path="/apply" element={<Apply/>}/>
                <Route path="/finder" element={<Finder/>}/>
                <Route path="/services" element={<Services/>}/>
                <Route path="/contact" element={<Contact/>}/>
                <Route path="/warranty" element={<Warranty/>}/>
            </Routes>
        </BrowserRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
