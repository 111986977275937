import './Marks.scss';

function Marks() {
  const marks = [
    'Audi',
    'Cadillac',
    'Chevrolet',
    'Chrysler',
    'Ford',
    'GMC',
    'Honda',
    'Hyundai',
    'INFINITI',
    'Jeep',
    'KIA',
    'Lexus',
    'Lincoln',
    'Maserati',
    'Mazda',
    'Mercedes-Benz',
    'Nissan',
    'Toyota',
  ];
  const listMarks = marks.map((mark) =>
    <div className="col-4 text-center" key={ mark }>
      <a href="/inventory/make=" >{ mark }</a>
    </div>
  );

  return (
    <div className="marks container">
      <div className="row justify-content-center">
        <div className="col-12">
          <h2 className="text-center">SHOP BY MAKE</h2>
        </div>

        <div className=" col-10">
          <div className="row">
            { listMarks }
          </div>
        </div>
      </div>
    </div>
  );
}

export default Marks;
