import './Footer.scss';

function Footer() {
    return (
      <footer className="footer">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-6 col-lg-2">
              <h6>Services</h6>

              <ul>
                <li>
                  <a href="/inventory">Inventory</a>
                </li>

                <li>
                  <a href="/apply">Apply Online</a>
                </li>

                <li>
                  <a href="/finder">Car Finder</a>
                </li>

                <li>
                  <a href="/services">Mechanic Services & Collision
</a>
                </li>
              </ul>
            </div>

            <div className="col-12 col-md-6 col-lg-2">
              <h6>About G&C</h6>

              <ul>
                <li>
                  <a href="/about">About Us</a>
                </li>

                <li>
                  <a href="/contact">Contact Us</a>
                </li>

                <li>
                  <a href="/policy">Privacy Policy</a>
                </li>
              </ul>
            </div>

            <div className="col-12 col-md-6 col-lg-3 col-xl-2">
              <h6>HOW TO REACH US?</h6>

              <div className="block">
                <div className="icon">
                  <i className="fas fa-map-marker-alt"/>
                </div>

                <div className="legend">
                  6595 Westbank Expy, Marrero, LA 70072
                </div>
              </div>

              <h6>SALES</h6>

              <div className="block">
                <div className="icon">
                  <i className="fas fa-envelope"/>
                </div>

                <div className="legend">
                  <a href="mailto:gcautodeals@gmail.com">gcautodeals@gmail.com</a>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6 col-lg-4 col-xxl-2">
              <h6>STORE HOURS</h6>

              <div className="block">
                <div className="icon">
                  <i className="fas fa-calendar"/>
                </div>

                <div className="legend">
                  <strong>Mon - Sat</strong> 9:00 AM - 7:00 PM<br/>
                  <strong>Sun</strong> Closed
                </div>
              </div>

              <h6>Social Networks</h6>

              <div className="block">
                <div className="icon">
                  <a href="https://www.facebook.com/gandcautola/"><i className="fa-brands fa-facebook-f"></i></a>
                </div>
                <div className="icon">
                  <a href="https://www.instagram.com/gandcauto/"><i className="fa-brands fa-instagram"></i></a>
                </div>
              </div>
            </div>
          </div>

          <div className="copyright row justify-content-center">
            <div className="col-12 col-xl-8">
              <div className="row justify-content-between">
                <div className="col-12 col-md-6 text-center text-md-start">
                  ©2022 G&C Auto Sales & Service
                </div>

                <div className="col-12 col-md-6 text-center text-md-end">
                  Website by: Inpaktu
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
  );
}

export default Footer;
